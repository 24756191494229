<script>
export default {
  
  data() {
    return {
        title: "",
        navbar: [
            {
              title: "Paramètres Géneral",
              route: "base.settings.index",
              icon: "mdi mdi-cog",
            },
            {
              title: "Contrats",
              route: "base.settings.contrats.index",
              icon: "bx bx-receipt",
            },
            {
              title: "Hébergement",
              route: "base.settings.hebergement.index",
              icon: "bx bx-home-circle",
              color:"blue",
            },
            {
              title: "Opérations",
              route: "base.settings.operations.index",
              icon: "bx bxs-report",
            },
            {
              title: "Requêtes",
              route: "base.settings.requets.index",
              icon: "bx bx-vertical-center",
            },
            {
              title: "Stock",
              route: "base.settings.stock.index",
              icon: "bx bx-data",
            },
            {
              title: "Technique",
              route: "base.settings.techniques.index",
              icon: "bx bx-notification",
            },
        ],
      series: [76],
      chartOptions: {
        chart: {
          height: 150,
          type: "radialBar",
          sparkline: {
            enabled: true,
          },
        },
        colors: ["#556ee6"],
        plotOptions: {
          radialBar: {
            startAngle: -90,
            endAngle: 90,
            track: {
              background: "#e7e7e7",
              strokeWidth: "97%",
              margin: 5, // margin is in pixels
            },
            hollow: {
              size: "60%",
            },

            dataLabels: {
              name: {
                show: false,
              },
              value: {
                offsetY: -2,
                fontSize: "16px",
              },
            },
          },
        },
       
      },
    };
  },
};
</script>

<template>
    <div class="card-body">
        <div class="d-flex flex-column h-100">
        <div class="mb-4">
            
            <ul class="list-unstyled categories-list" v-for="nav in navbar" v-bind:key="nav.title">
                <li>
                    <router-link :to="{ name: nav.route }"
                    href="javascript: void(0);"
                    class="text-body d-flex align-items-center"
                    >
                    <i class="text-muted font-size-24 me-2" :class="nav.icon " ></i>
                    <span class="me-auto font-size-17">  {{ nav.title }}</span
                    >
                    </router-link>
            </li>
            
            </ul>
        </div>
        </div>
    </div>
</template>